import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/login/index')
  },
  {
    path: '/',
    name: 'home',
    redirect: '/welcome',
    component: Layout,
    children: [
      {
        name:"欢 迎",
        path:'/welcome',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/Welcome'),
        // meta:{
        //   namePath:['欢 迎'],
        // }
      },
      {
        name:"账户信息",
        path:'/account',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/Account'),
        // meta:{
        //   namePath:['用 户'],
        // }
      },
    ]
  },
  {
    path: '/sys',
    name: '系统设置',
    redirect: '/sys/user',
    component: Layout,
    children: [
      {
        name:'用户管理',
        path:'/sys/user',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/sys/User/index.vue'),
        meta:{
          namePath:['系统设置','用户管理'],
        }
      },
      {
        name:'角色管理',
        path:'/sys/role',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/sys/Role/index.vue'),
        meta:{
          namePath:['系统设置','角色管理'],
        }
      },
      {
        name:'权限管理',
        path:'/sys/permission',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/sys/Permission/index.vue'),
        meta:{
          namePath:['系统设置','权限管理'],
        }
      },
      {
        name:'系统日志',
        path:'/sys/log',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/sys/Log/index.vue'),
        meta:{
          namePath:['系统设置','系统日志'],
        }
      }
    ]
  },
  {
    path: '/aiwh',
    name: '智能仓储',
    redirect: '/aiwh/warehouse',
    component: Layout,
    children: [
      {
        name:"",
        path:'/aiwh/warehouse',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/AIWH/Warehouse'),
        meta:{
          namePath:['智能仓储','仓库分区管理'],
        }
      },
      {
        name:"",
        path:'/aiwh/goodsTemplate',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/AIWH/GoodsTemplate'),
        meta:{
          namePath:['智能仓储','货物品类管理'],
        }
      },
      {
        name:"",
        path:'/aiwh/stocklog',
        component:()=> import(/* webpackChunkName: "tagOrders" */ '@/views/AIWH/Stocklog'),
        meta:{
          namePath:['智能仓储','仓库日志'],
        }
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
