import axios from 'axios'
import router from '../router';
import { Message, MessageBox } from 'element-ui';
import _ from 'lodash'

// 创建axios实例
const service = axios.create({
    // withCredentials: true, // 允许跨域请求时携带cookie
    // baseURL: process.env.VUE_APP_BACKEND_URL// api 的 base_url
    baseURL: '/api'// api 的 base_url

})

service.interceptors.response.use(
    response => {
        console.log('response',response)

        if (!response || response.status != 200 || !response.data) {
            Message.error('系统异常')
            return Promise.resolve(response)
        }
        if (_.startsWith(response.config.url, '/login')) {
            return Promise.resolve(response.data)
        }
        // 根据 response.data.code 进行处理
        switch (response.data.code) {
            case 200:
                // 成功处理
                break;
            case 401:
                // 登出，跳转到登录页面
                MessageBox.confirm('登录已过期,请重新登录！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 用户点击了确定按钮，执行登出和跳转操作
                    // 这里可以添加您的登出逻辑，例如清除 token 等
                    localStorage.removeItem("AIWHUser");
                    localStorage.removeItem("AIWHRoles");
                    localStorage.removeItem("AIWHPermissions");
                    localStorage.removeItem("AIWHIsSuper");
                    if (router.path !== '/login') {
                        router.push('/login');
                    }
                }).catch((error) => {
                    // 用户点击了取消按钮
                    console.log('取消',error)
                });
                break;
            case 403:
                Message.error('权限不足，请联系管理员');
                break;
            case 404:
                // Message.error('未找到资源');
                break;
            case 500:
                Message.error('系统异常');
                break;
            default:
                Message.error(response.data.msg);
                ;
        }
        return Promise.resolve(response.data);
    },
    error => {
        console.log('error',error)
        if(error.response.status == 400){
            //上传错误
            Message.error(error.response.data.msg);
        }
        return Promise.reject(error.response)
    }
)

export default service
