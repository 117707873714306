<template>
  <div>
    <el-menu
      ref="menu"
      class="nav-menu"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      :collapse-transition="false"
      :router="true"
      :unique-opened="true"
    >
      <el-submenu
        v-for="(menu, index) in menus"
        :key="index"
        :index="menu.menuPath"
      >
        <template slot="title">
          <i :class="menu.icon"></i>
          <span slot="title">{{ menu.name }}</span>
        </template>
        <el-menu-item
          v-for="(menu2, index2) in menu.children"
          :key="index2"
          :index="menu2.menuPath"
        >
          <span slot="title">{{ menu2.name }}</span>
          <!-- <el-menu-item index="1-4-1">选项1</el-menu-item> -->
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="nav-footer">
      <el-button
        type="primary"
        @click="chageCollapse"
        style="font-size: 18px; border: 0px; margin-left: 10px; color: #fff"
        :icon="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        circle
      ></el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      isCollapse: true,
      menus: [],
    };
  },
  methods: {
    chageCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit("changeCollapse", this.isCollapse);
      localStorage.setItem(
        "AIWHMenusIsCollapse",
        this.isCollapse == true ? 1 : 0
      );
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    initMenus() {
      let menus = JSON.parse(localStorage.getItem("AIWHPermissions"));
      menus = menus.filter((item) => {
        return item.type == 0;
      });
      menus = _.sortBy(menus, (a) => a.sort);
      menus.forEach((item) => {
        item.children = [];
      });

      // 构建树结构
      const menuTree = [];
      menus.forEach((item) => {
        // 如果节点没有父节点，那么它是一个根节点
        if (!item.parentUuid) {
          menuTree.push(item);
        } else {
          // 否则，找到其父节点，并将当前节点添加到父节点的children数组中
          const parent = _.find(menus, { uuid: item.parentUuid });
          if (parent) {
            parent.children.push(item);
          }
        }
      });
      this.menus = menuTree;

      // 判断是否折叠
      let isCollapse = localStorage.getItem("AIWHMenusIsCollapse");
      if (_.isNil(isCollapse)) {
        localStorage.setItem(
          "AIWHMenusIsCollapse",
          this.isCollapse == true ? 1 : 0
        );
      } else {
        this.isCollapse = isCollapse == 1 ? true : false;
        this.$emit("changeCollapse", this.isCollapse);
      }
    },
  },
  mounted() {
    this.initMenus();
  },
};
</script>

<style lang="scss" scoped>
.nav-menu:not(.el-menu--collapse) {
  width: 180px;
  // min-height: 400px;
}
.nav-menu {
  // border: 1px solid #7a7a7a;
  height: calc(100vh - 115px);
  overflow-y: auto;
  text-align: left;
}
.nav-footer {
  height: 55px;
  line-height: 55px;
  // border: 1px solid #7a7a7a;
  border: 1px solid #e6e6e6;
  text-align: left;
  // background-color: #545c64;
}
.el-button--primary {
  // background-color: #545c64;
  background-color: #7365ff;
}
</style>