import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'

Vue.directive('permission', {
  inserted: function (el, binding) {
    // 这里，binding.value 是传递给指令的值，你可以用它来判断权限
    let hasPermission = store.getters.checkPermission(binding.value)
    console.log(binding.value,hasPermission)
    if (!hasPermission) {
      // 如果没有权限，移除元素
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
  
  // update: function (el, binding) {
  //   // 这里，binding.value 是传递给指令的值，你可以用它来判断权限
  //   if (!store.getters.checkPermission(binding.value)) {
  //     // 如果没有权限，移除元素
  //     el.parentNode && el.parentNode.removeChild(el);
  //   }
  // }
});

Vue.config.productionTip = false

Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
