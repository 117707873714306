<template>
  <el-container style="height: 100vh; border: 1px solid #eee">
    <el-header
      style="text-align: left; padding-right: 0; background-color: #7365ff"
      height="60px"
    >
      <span class="logo" @click="$router.push('/welcome')">AI Warehouse</span>
      <el-dropdown
        style="float: right; padding: 0 20px 0 0; cursor: pointer"
        size="medium"
        @command="handleCommand"
      >
        <div>
          <el-avatar
            :size="35"
            style="vertical-align: middle"
            :src="avatarSrc"
          ></el-avatar>
          <span
            style="
              font-size: 16px;
              margin-left: 10px;
              color: #fff;
              font-weight: bold;
            "
            >{{ truncate(userName) }}</span
          >
          <el-dropdown-menu slot="dropdown" style="text-align: center">
            <el-dropdown-item icon="el-icon-user" command="accountManager"
              >账户管理</el-dropdown-item
            >
            <el-dropdown-item
              icon="el-icon-switch-button"
              divided
              command="logout"
              >登 出</el-dropdown-item
            >
          </el-dropdown-menu>
        </div>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '65px' : '181px'">
        <nav-menu @changeCollapse="changeCollapse"></nav-menu>
      </el-aside>

      <el-main style="margin: 0; padding: 0">
        <el-header
          v-if="namePath && namePath.length > 0"
          style="
            text-align: left;
            padding-right: 0;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            line-height: 35px;
            border-bottom: 1px solid #dcdfe696;
          "
          height="35px"
        >
          <span
            style="font-size: 14px; font-weight: bold"
            v-for="path of namePath"
            :key="path"
            >{{ " / " + path }}</span
          >
        </el-header>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import _ from "lodash";
import NavMenu from "@/components/NavMenu";
import { logout } from "@/api/user";
export default {
  components: {
    NavMenu,
  },
  watch: {
    $route(to, from) {
      this.namePath = to.meta.namePath; // 更新namePath以反映新的路由信息
    },
  },
  data() {
    return {
      isCollapse: true,
      userName: "",
      avatarSrc: require("@/assets/user.png"),
      namePath: [],
    };
  },
  methods: {
    handleCommand(command) {
      if (command === "logout") {
        return this.logout();
      }
      if (command === "accountManager") {
        this.$router.push("/account");
        return;
      }
    },
    logout() {
      this.$confirm("确定要登出系统吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // 用户点击了确定按钮，执行登出和跳转操作
          // 这里可以添加您的登出逻辑，例如清除 token 等
          let resp = await logout();
          if (resp.code === 200) {
            localStorage.removeItem("AIWHUser");
            localStorage.removeItem("AIWHRoles");
            localStorage.removeItem("AIWHPermissions");
            localStorage.removeItem("AIWHIsSuper");
            if (this.$router.path !== "/login") {
              this.$router.push("/login");
            }
          }
        })
        .catch((error) => {
          // 用户点击了取消按钮
          console.log("取消", error);
        });
    },
    truncate(str) {
      return _.truncate(str, {
        length: 24,
      });
    },

    changeCollapse(value) {
      this.isCollapse = value;
    },
  },

  mounted() {
    let user = localStorage.getItem("AIWHUser");
    let roles = localStorage.getItem("AIWHRoles");
    let permissions = localStorage.getItem("AIWHPermissions");
    let isSuper = localStorage.getItem("AIWHIsSuper");
    if (user && roles && permissions && (isSuper == 0 || isSuper == 1)) {
      this.userName = JSON.parse(user).name;
    //   this.user = JSON.parse(user);
    //   console.log('this.$router',this.$router)
    //   if (this.user.hadChangedPasswd != 1&&this.$router.path !== "/account") {
    //       this.$router.push("/account");
    //   }
    } else {
      if (this.$router.path !== "/login") {
        this.$router.push("/login");
      }
    }
    this.namePath = this.$route.meta.namePath; // 如果你将namePath放在了meta字段中
  },
};
</script>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.el-header {
  // background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.page-main {
  padding: 20px;
  height: calc(100vh - 135px);
  overflow-y: auto;
  text-align: left;
}
</style>