import request from '../utils/request-util'

export function getUsersPage(params) {
  return request({
    url: '/sys/user/page',
    method: 'get',
    params
  })
}

export function getRolesPage(params) {
  return request({
    url: '/sys/role/page',
    method: 'get',
    params
  })
}

export function getRolesTree(params) {
  return request({
    url: '/sys/role/tree',
    method: 'get',
    params
  })
}

export function createRole(data) {
  return request({
    url: '/sys/role',
    method: 'post',
    data
  })
}

export function updateRole(data) {
  return request({
    url: '/sys/role',
    method: 'put',  
    data
  })
}


export function createUser(data) {
  return request({
    url: '/sys/user',
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: '/sys/user',
    method: 'put',  
    data
  })
}

export function updateUserPasswd(data) {
  return request({
    url: '/sys/user/passwd',
    method: 'put',  
    data
  })
}



export function createPermission(data) {
  return request({
    url: '/sys/permission',
    method: 'post',
    data
  })
}



export function updatePermission(data) {
  return request({
    url: '/sys/permission',
    method: 'put',  
    data
  })
}

export function deletePermission(data) {
  return request({
    url: '/sys/permission',
    method: 'delete',  
    data
  })
}

export function getPermissionsTree(params) {
  return request({
    url: '/sys/permission/tree',
    method: 'get',  
    params
  })
}



export function logout(data) {
  return request({
      url: '/sys/user/logout',
      method: 'post',
      data
  })
}

