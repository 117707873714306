import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    checkPermission: (state) => (permissions) => {
      if(localStorage.getItem('AIWHIsSuper') == 1){
        return true;
      }

      let hasPermission = false;
      // 从localStorage获取权限信息
      const userPermissions = JSON.parse(localStorage.getItem('AIWHPermissions')) || [];
      if (_.isArray(permissions)) {
        permissions.forEach(item => {
          let index = _.findIndex(userPermissions, { permissionFlag: item })
          if (index > -1) {
            hasPermission = true;
          }
        })
      }
      if (_.isString(permissions)) {
        let index = _.findIndex(userPermissions, { permissionFlag: permissions })
        if (index > -1) {
          hasPermission = true;
        }
      }

      // 检查用户是否具有特定权限
      return hasPermission;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
